.checkbox {
  position: relative;
  user-select: none;
  margin-bottom: 16px;

  &.invalid {
    .checkbox-text {
      color: $red-color;

      &::before {
        border-color: $red-color;
      }
    }
  }

  &-label {
    margin-bottom: 0;

    &.disabled {
      .checkbox-text {
        &::before {
          border-color: $black-color;
        }
      }
    }
  }

  &-input {
    position: absolute;
    opacity: 0;
    z-index: -1;

    &:focus {
      +span {
        &::before {
          border-color: $white-color;
        }
      }
    }

    &:checked {
      +span {
        &::after {
          opacity: 1;
        }
      }
    }
  }

  &-text {
    position: relative;
    display: inline-block;
    align-items: center;
    min-height: 20px;
    padding-left: 30px;
    cursor: pointer;
    font-size: 14px;
    line-height: 1.5;
    color: $white-color;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      transition: all 0.3s ease;
    }

    &::before {
      width: 20px;
      height: 20px;
      border: 1px solid #939393;
      background-color: $black-color;
    }

    &::after {
      content: '';
      width: 12px;
      height: 7px;
      border-left: 2px solid $white-color;
      border-bottom: 2px solid $white-color;
      transform: rotate(-45deg);
      opacity: 0;
      top: 4px;
      left: 4px;
    }
  }
}