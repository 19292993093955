.box-light {
	position: relative;
	padding: vwm(40px);

	@include minW(640) {
		padding: vwt(50px);
	}

	@include minW(992) {
		padding: vwd(50px);
	}

	@include minW(1440) {
		padding: 50px;
	}

	&::before,
	&::after {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		border-radius: 12px;
		overflow: hidden;
	}

	&::before {
		background: linear-gradient(135deg,  rgba(62,57,50,1) 0%,rgba(145,133,117,1) 35%,rgba(145,133,117,1) 70%,rgba(62,57,50,1) 100%);
	}

	&::after {
		background: url('../../images/bg-join.png') no-repeat top right;
		background-size: cover;
		width: calc(100% - 4px);
		height: calc(100% - 4px);
		top: 2px;
		left: 2px;
	}

	&__main {
		&::before,
		&::after {
			content: '';
			display: block;
			position: absolute;
			z-index: 2;
		}
	}

	&__body {
		position: relative;
		z-index: 2;
	}

	&--type1 {
		.box-light {
			&__main {
				&::before {
					width: 40%;
					height: 4px;
					background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,0) 100%);
					top: -1px;
					right: 10%;

					@include minW(992) {
						width: 15%;
					}
				}

				&::after {
					width: 4px;
					height: 15%;
					background: linear-gradient(to top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,0) 100%);
					top: 15%;
					left: -1px;

					@include minW(992) {
						height: 30%;
					}
				}
			}
		}
	}

	&--type2 {
		&::after {
			background: linear-gradient(45deg,  rgba(94,86,72,1) 0%,rgba(12,10,6,1) 15%,rgba(12,10,6,1) 50%,rgba(12,10,6,1) 80%,rgba(12,10,6,1) 80%,rgba(63,59,48,1) 100%);
		}

		.box-light {
			&__main {
				&::before {
					width: 40%;
					height: 4px;
					background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,0) 100%);
					top: -1px;
					right: 10%;

					@include minW(992) {
						width: 15%;
					}
				}

				&::after {
					width: 4px;
					height: 15%;
					background: linear-gradient(to top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,0) 100%);
					top: 15%;
					left: -1px;

					@include minW(992) {
						height: 30%;
					}
				}
			}
		}
	}

	&--type3 {
		.box-light {
			&__main {
				&::before {
					width: 40%;
					height: 4px;
					background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,0) 100%);
					top: -1px;
					right: 10%;

					@include minW(992) {
						width: 15%;
					}
				}

				&::after {
					width: 40%;
					height: 4px;
					background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,0) 100%);
					bottom: -1px;
					left: 25%;

					@include minW(992) {
						width: 15%;
					}
				}
			}
		}
	}

	+ .box-light {
		margin-top: 30px;

		@include minW(992) {
			margin-top: 50px;
		}
	}
}