.modal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(#000, 0.85);
	opacity: 0;
	visibility: hidden;
	z-index: 999;
	overflow-x: hidden;
	overflow-y: auto;

	&__dialog {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: calc(100% - 64px);
		margin: vwm(24px);

		@include minW(640) {
			margin: vwt(32px) vwt(96px);
		}

		@include minW(992) {
			margin: vwd(32px);
		}

		@include minW(1440) {
			margin: 32px;
		}
	}

	&__main {
		position: relative;
		padding: 20px;
		width: 100%;
		max-width: 750px;

		&::before,
		&::after {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			border-radius: 12px;
			overflow: hidden;
		}

		&::before {
			background: linear-gradient(135deg,  rgba(62,57,50,1) 0%,rgba(145,133,117,1) 35%,rgba(145,133,117,1) 70%,rgba(62,57,50,1) 100%);
		}

		&::after {
			background: url('../../images/bg-join.png') no-repeat top right;
			background-size: cover;
			width: calc(100% - 4px);
			height: calc(100% - 4px);
			top: 2px;
			left: 2px;
		}
	}

	&__light {
		&::before,
		&::after {
			content: '';
			display: block;
			position: absolute;
			z-index: 2;
		}

		&::before {
			width: 15%;
			height: 4px;
			background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,0) 100%);
			top: -1px;
			right: 10%;
		}

		&::after {
			width: 4px;
			height: 30%;
			background: linear-gradient(to top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,0) 100%);
			top: 50%;
			left: -1px;
		}
	}

	&__close {
		position: absolute;
		right: 10px;
		top: 10px;
		width: 24px;
		height: 24px;
		font-size: 0;
		background-color: $white-color;
		border: 0;
		outline: 0;
		border-radius: 50%;
		padding: 0;
		transition: transform 0.3s ease-out;
		z-index: 3;

		@include minW(640) {
			width: 40px;
			height: 40px;
			right: 16px;
			top: 16px;
		}

		&:hover {
			transform: rotate(180deg);
		}

		span {
			display: block;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;

			&::before,
			&::after {
				content: '';
				display: block;
				position: absolute;
				width: 14px;
				height: 2px;
				border-radius: 2px;
				background-color: $black-color;

				@include minW(640) {
					width: 24px;
					height: 4px;
				}
			}

			&::before {
				transform: rotate(45deg);
			}

			&::after {
				transform: rotate(-45deg);
			}
		}
	}

	&__body {
		position: relative;
		z-index: 2;
	}

	&--show {
		opacity: 1;
		visibility: visible;
	}

	&--gallery,
	&--youtube {
		.modal {
			&__main {
				padding: 0;
			}
		}
	}

	&--guide,
	&--loser {
		.modal {
			&__main {
				max-width: 600px;
			}
		}
	}

	&--gallery {
		.modal {
			&__close {
				right: auto;
				left: -30px;

				@include minW(640) {
					left: -60px;
				}

				@include minW(992) {
					left: -60px;
				}

				@include minW(1440) {
					left: -60px;
				}

				&::before {
					font-family: $icon-font;
					content: "\e902";
					color: $black-color;
					font-size: 16px;
					line-height: 1;

					@include minW(640) {
						left: -60px;
						font-size: 16px;
					}

					@include minW(992) {
						font-size: 20px;
					}

					@include minW(1440) {
						left: -60px;
						font-size: 20px;
					}
				}

				span {
					display: none;
				}

				&:hover {
					transform: none;
				}
			}

			&__body {
				position: relative;
			}

			&__main {
				background: none;
				box-shadow: none;
				border-color: transparent;
				max-width: 300px;

				@include minW(640) {
					max-width: 400px;
				}

				@include minW(992) {
					max-width: 480px;
				}
			}
		}
	}
}

.prize-winner {
	&__title {
		font-size: vwm(22px);

		@include minW(640) {
			font-size: vwt(28px);
		}

		@include minW(992) {
			font-size: vwd(17px);
		}

		@include minW(1400) {
			font-size: 17px;
		}

		span {
			display: block;
			font-size: vwm(32px);

			@include minW(640) {
				font-size: vwt(50px);
			}

			@include minW(992) {
				font-size: vwd(34px);
			}


			@include minW(1400) {
				font-size: 34px;
			}
		}
	}
}

.loser {
	&__title {
		font-size: 34px;

		span {
			display: block;
		}
	}
}

.prize-winner {
	&__title {
		padding: 0 vwm(20px);

		@include minW(640) {
			padding: 0 vwt(50px);
		}

		@include minW(992) {
			padding: 0 vwd(50px);
		}

		@include minW(992) {
			padding: 0 50px;
		}
	}

	&__image {
		width: 100%;
		max-width: 300px;
		margin: 0 auto;
	}
}