.my-profile {
	position: relative;
	padding: 30px 0;

	@include minW(992) {
		padding: 60px 0;
	}
}

.my-prize {
	.reward-invalid {
		color: red;
	}

	.reward-new {
		color: #d0c7b7ee;

		span {
			font-size: 12px;
		}
	}

	.reward-confirm {
		color: #0072d3;
	}
}