.finalEvent{
  border: 1px solid #897d6b;
  border-radius: 15px;
  background: rgb(115,107,89);
  background: -moz-linear-gradient(170deg, rgba(115,107,89,1) 0%, rgba(255,255,255,0) 35%, rgba(255,255,255,0) 100%);
  background: -webkit-linear-gradient(170deg, rgba(115,107,89,1) 0%, rgba(255,255,255,0) 35%, rgba(255,255,255,0) 100%);
  background: linear-gradient(170deg, rgba(115,107,89,1) 0%, rgba(255,255,255,0) 35%, rgba(255,255,255,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#736b59",endColorstr="#ffffff",GradientType=1);
  font-size: 15px;
  text-align: left;
  position: relative;
  overflow: hidden;

  a{
    color: #fff;
    text-decoration: underline;
    &:hover{
      text-decoration: none;
    }
  }
  p{
    font-size: 18px;
  }
  address{
    padding: 10px 20px 10px 80px;
    margin-bottom: 0;
    display: block;
    background: url('../../images/icon-address.png')  no-repeat 20px 15px;
    p{
      margin-bottom: 8px;
    }
  }
  time{
    border-radius: 0 15px 0 0;
    display: block;
    background: rgb(115,107,89);
    background: -moz-linear-gradient(189deg, rgba(115,107,89,1) 0%, rgba(255,255,255,0) 35%, rgba(255,255,255,0) 100%);
    background: -webkit-linear-gradient(189deg, rgba(115,107,89,1) 0%, rgba(255,255,255,0) 35%, rgba(255,255,255,0) 100%);
    background: linear-gradient(189deg, rgba(115,107,89,1) 0%, rgba(255,255,255,0) 35%, rgba(255,255,255,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#736b59",endColorstr="#ffffff",GradientType=1);
  }
  .liveStream{
    padding: 15px 20px 5px 80px;
    background: url('../../images/icon-time.png')  no-repeat 20px 10px;
  }
  .winnerList{
    text-align: center;
    padding: 10px 16%;
    position: relative;
    overflow: hidden;
    &:before{
      content:'';
      display: block;
      width: 50%;
      height:50px;
      border-radius: 100%;
      background: rgb(115,107,89);;
      position: absolute;
      top: -61%;
      left: 50%;
      transform: translateX(-50%);
      z-index: 0;
      box-shadow: 0px 0px 70px #5e5848;
    }
    p{
      position: relative;
      z-index: 1;
    }
  }


  &:after{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    background: url('../../images/winner_flare.png') center center/cover no-repeat;
    mix-blend-mode: screen;
  }
  .finalEvent__t{
    padding: 3% 6%;
    text-align: center;
    position: relative;
    z-index: 1;
  }
  .finalEvent__b{
    padding: 3% 6%;
    text-align: center;
    border-radius: 0 0 15px;
    overflow: hidden;
    display: block;
    position: relative;
    z-index: 1;
    background: rgb(115,107,89);
    background: -moz-linear-gradient(350deg, rgba(115,107,89,1) 0%, rgba(255,255,255,0) 35%, rgba(255,255,255,0) 100%);
    background: -webkit-linear-gradient(350deg, rgba(115,107,89,1) 0%, rgba(255,255,255,0) 35%, rgba(255,255,255,0) 100%);
    background: linear-gradient(350deg, rgba(115,107,89,1) 0%, rgba(255,255,255,0) 35%, rgba(255,255,255,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#736b59",endColorstr="#ffffff",GradientType=1);
    &:before{
      content:'';
      display: block;
      width: 50%;
      height:50px;
      border-radius: 100%;
      background: rgb(115,107,89);;
      position: absolute;
      top: -51px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 0;
      box-shadow: 0px 0px 70px #5e5848;
    }
    article{
      position: relative;
      z-index: 1;
    }
  }
  .isMo{
    display: none;
  }

  @include maxW(470) {
    font-size: 3vw;
    .isMo{
      display: inline;
    }
    p{
      font-size: 4vw;
    }
    .winnerList{
      padding: 10px 12%;
      &:before{
        top: -73%;
      }
      p{
        font-size: 3.2vw;
      }

      .finalEvent__t{
        padding: 3%;
      }
    }
  }
}