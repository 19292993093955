.icon {
	font-family: $icon-font;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	speak: never;

	&--facebook:before {
		content: "\e910";
	}
	&--visibility-off:before {
		content: "\e900";
	}
	&--visibility:before {
		content: "\e901";
	}
	&--arrow-left:before {
		content: "\e902";
	}
	&--arrow-right:before {
		content: "\e903";
	}
	&--chevron-left:before {
		content: "\e904";
	}
	&--chevron-right:before {
		content: "\e905";
	}
	&--account:before {
		content: "\e906";
	}
	&--burger:before {
		content: "\e907";
	}
	&--close:before {
		content: "\e908";
	}
	&--logout:before {
		content: "\e909";
	}
	&--upload:before {
		content: "\e90a";
	}
	&--cancel-circle:before {
		content: "\e90b";
	}
	&--check-circle:before {
		content: "\e90c";
	}
	&--pending-circle:before {
		content: "\e90d";
	}
	&--account-circle:before {
		content: "\e90e";
	}
	&--picture-pdf:before {
		content: "\e90f";
	}
}