.pin {
	position: relative;
	font-size: vwm(17px);

	@include minW(640) {
		font-size: vwt(17px);
	}

	@include minW(992) {
		font-size: vwd(17px);
	}

	@include minW(1440) {
		font-size: 17px;
	}

	&__label {
		margin-bottom: 4px;
		font-weight: 700;
		color: rgba(#fff, 0.6);
	}

	&__value {
		border: 1px solid rgba(#b2a28c, 0.3);
		padding: 10px 8px;
		border-radius: 8px;
		color: rgba(#fff, 0.3);

		@include minW(992) {
			padding: 12px 16px;
		}
	}
}