.field {
	position: relative;
	margin-bottom: 16px;
	color: $white-color;

	&__label {
		display: block;
		margin-bottom: 8px;
	}

	&__control {
		position: relative;

		&--select {
			&::after {
				border: 2px solid transparent;
				border-radius: 2px;
				border-right: 0;
				border-top: 0;
				content: '';
				display: block;
				height: 0.625em;
				margin-top: -0.4375em;
				pointer-events: none;
				position: absolute;
				top: 50%;
				transform: rotate(-45deg);
				transform-origin: center;
				width: 0.625em;
				right: 16px;
				border-color: #fff;
			}
		}

		&--password {
			.field {
				&__input {
					padding-right: 40px;
				}
			}
		}

		.btn {
			position: absolute;
			top: 0;
			right: 0;
			width: 40px;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			text-decoration: none;
			z-index: 2;
		}
	}

	&__input {
		width: 100%;
		background-color: #101010;
		border: 1px solid #8e826f;
		height: 40px;
		padding: 6px 12px;
		color: #fff;
		outline: 0;
		border-radius: 8px;

		&:focus {
			outline: 0;
			border-color: #fff;
		}

		&[disabled] {
			color: rgba($white-color, 0.5);
		}
	}

	&__birthday {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		border: 0;
		background-color: transparent;
		outline: 0;

		&::after {
			content: '';
			display: block;
			position: absolute;
			right: 12px;
			top: 50%;
			transform: translateY(-50%);
			background: url('../../images/icon-calendar.png') no-repeat center;
			background-size: cover;
			width: 16px;
			height: 14px;
		}
	}

	&__error {
		font-size: 12px;
		color: #ff0000;
		margin-top: 6px;
	}

	&__select {
		cursor: pointer;
		display: block;
		width: 100%;
		outline: 0;
		background-color: #101010;
		border: 1px solid #939393;
		height: 40px;
		border-radius: 8px;
		padding: 6px 43px 6px 12px;
		white-space: nowrap;
		overflow: hidden !important;
		text-overflow: ellipsis;
		color: $white-color;
		-webkit-appearance: none;

		&[disabled] {
			color: rgba($white-color, 0.5);
		}

		&:focus {
			border-color: $white-color;
		}
	}
}