.prizes {
	position: relative;
	padding: 30px 0;
	text-align: center;
	overflow: hidden;

	@include minW(992) {
		padding: 60px 0;
	}

	&__title {
		display: flex;
		justify-content: center;
	}

	&__body {

		@include minW(640) {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
		}
	}

	&__item {
		margin-bottom: 16px;

		@include minW(640) {
			width: 50%;
			flex-basis: 50%;
			margin-bottom: 0;
		}

		@include minW(992) {
			width: 25%;
			flex-basis: 25%;
		}
	}
}

.card-prize {
	position: relative;
	font-size: 17px;

	&__title {
		margin-bottom: 6px;
	}

	&__name {
		font-family: $secondary-font;
		color: #c1b396;
		margin-bottom: 4px;
	}

	&__price {
		font-weight: 800;

		span {
			font-size: 13px;
			font-weight: 400;
		}
	}

	&__note {
		font-size: 12px;
		color: #ccc;
		margin-top: 10px;
	}
}