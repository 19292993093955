.container {
	width: 100%;
	max-width: 1212px;
	padding: 0 16px;
	margin: 0 auto;
}

.img {
	max-width: 100%;

	&--fluid {
		width: 100%;
		display: block;
	}
}

.page {
	position: relative;
	padding-top: 55px;
	background: url('../../images/bg-join.png') no-repeat top right;
	background-size: cover;
	overflow: hidden;

	@include minW(992) {
		padding-top: 58px;
		min-height: calc(100vh - 180px);
	}

	&--luckydraw {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.Toastify__progress-bar-theme--light {
	background: #c1b396 !important;
}

.title {
	font-family: $secondary-font;
	background-image: url('../../images/bg-text.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	color: transparent;
	background-clip: text;
	line-height: 1.6;

	span {
		display: block;
	}

	&--primary {
		font-size: vwm(40px);
		line-height: 1.2;

		@include minW(640) {
			font-size: vwt(50px);
		}

		@include minW(992) {
			font-size: vwd(50px);
		}

		@include minW(1440) {
			font-size: 50px;
		}
	}

	&--secondary {
		font-size: vwm(30px);

		@include minW(640) {
			font-size: vwt(40px);
		}

		@include minW(992) {
			font-size: vwd(40px);
		}

		@include minW(1440) {
			font-size: 40px;
		}
	}
}

.captcha {
	display: flex;
	justify-content: center;
	padding-bottom: 16px;

	+ .error {
		font-size: 12px;
		color: red;
		display: block;
		text-align: center;
		margin-top: -10px;
	}
}

.form--store {
	margin-bottom: 32px;

	@include minW(992) {
		display: flex;
		align-items: center;
		justify-content: center;

		padding: 0 48px;
	}

	.field {
		margin-bottom: 16px;

		@include minW(992) {
			flex-grow: 2;
			margin-bottom: 0;
			margin-right: 16px;
		}
	}
}