.auth {
	position: relative;
	padding: 30px 0;

	@include minW(992) {
		padding: 60px 0;
	}

	&__main {
		position: relative;
		display: flex;
		justify-content: center;
	}

	&__body {
		width: 100%;
		max-width: 400px;
	}

	&--register {
		.auth {
			&__body {
				max-width: 650px;
			}
		}
	}
}

.calendar {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 999;
	display: none;
	background-color: rgba(#000, 0.75);


	&__body {
		position: relative;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&--show {
		display: block;
	}
}