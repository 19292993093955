.header {
	position: fixed;
	background-color: #aaa599;
	width: 100%;
	z-index: 99;

	&__main {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__body {
		position: fixed;
		width: 100%;
		height: calc(100% - 55px);
		background-color: #fff;
		top: 55px;
		left: 0;
		right: 0;
		bottom: 0;
		transform: translateX(100%);
		transition: transform 0.3s ease-out;

		@include minW(992) {
			position: relative;
			transform: none;
			transition: none;
			background-color: transparent;
			display: flex;
			top: auto;
			left: auto;
			right: auto;
			bottom: auto;
			height: auto;
			width: auto;
		}
	}

	&--show {
		.header {
			&__body {
				transform: translateX(0%);
			}
		}
	}
}

.logo {
	display: block;
	padding: 16px 0;

	@include minW(640) {
		max-width: vwt(138px);
	}

	@include minW(992) {
		max-width: vwd(138px);
	}

	@include minW(1440) {
		max-width: 138px;
	}
}

.navbar {
	position: relative;
	list-style: none;
	margin-bottom: 16px;

	@include minW(992) {
		display: flex;
		margin-bottom: 0;
	}

	&__item {
		position: relative;

		@include minW(640) {
			padding: 0 vwt(30px);
		}

		@include minW(992) {
			padding: 0 vwd(12px);
		}

		@include minW(1440) {
			padding: 0 20px;
		}

		&::before {
			content: '';
			display: none;
			width: 1px;
			height: 30px;
			background-color: #32240c;
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);

			@include minW(992) {
				display: block;
			}
		}

		&:first-child {
			&::before {
				display: none;
			}
		}
	}

	&__link {
		padding: 20px 0 10px;
		display: inline-block;
		color: #32240c;
		font-family: $secondary-font;
		font-size: 14px;
		position: relative;
		transition: color 0.3s ease-out;

		@include minW(640) {
			font-size: vwt(14px);
		}

		@include minW(992) {
			display: block;
			padding: 20px 0;
			font-size: vwd(18px);
		}

		@include minW(1200) {
			font-size: vwd(14px);
		}

		@include minW(1440) {
			font-size: 14px;
		}

		&::after {
			content: '';
			display: block;
			width: 0;
			height: 3px;
			background-color: #000;
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			transition: width 0.3s ease-out;
		}

		&:hover {
			color: #000;
			text-decoration: none;

			&::after {
				width: 100%;
			}
		}

		&--active {
			color: #000;

			&::after {
				width: 100%;
			}
		}
	}
}

.navsign {
	position: relative;
	display: flex;
	align-items: center;
	list-style: none;
	margin-bottom: 0;

	&__item {
		padding: 0 10px;
		position: relative;

		&::before {
			content: '';
			display: block;
			width: 1px;
			height: 30px;
			background-color: #32240c;
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
		}

		&:first-child {
			padding-left: 0;

			&::before {
				display: none;
			}
		}

		&--register {
			.navsign {
				&__link {
					&::after {
						content: '';
						display: block;
						background: url('../../images/lock.png') no-repeat center;
						background-size: cover;
						width: 28px;
						height: 24px;
						margin-left: 6px;
					}
				}
			}
		}

		&--logout {
			.navsign {
				&__link {
					&::after {
						content: '';
						display: block;
						background: url('../../images/icon-logout.png') no-repeat center;
						background-size: cover;
						width: 20px;
						height: 20px;
						margin-left: 6px;
					}
				}
			}
		}
	}

	&__link {
		display: flex;
		align-items: center;
		color: #32240c;
		font-family: $secondary-font;
		font-size: 13px;
		line-height: 1.5;
		transition: color 0.3s ease-out;
		cursor: pointer;

		@include minW(992) {
			font-size: 11px;
		}

		&:hover {
			color: #000;
			text-decoration: none;
		}
	}
}

.nav-toggle {
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	font-size: vwm(50px);
	color: $black-color;

	@include minW(640) {
		font-size: vwt(50px);
	}

	@include minW(992) {
		display: none;
	}

	.icon {
		display: block;
	}
}