// @include fontFace('SamsungOne', '../../fonts/', 'SamsungOne-400', 400, normal);
// @include fontFace('SamsungOne', '../../fonts/', 'SamsungOne-700', 700, normal);
// @include fontFace('Samsung Sharp Sans', '../../fonts/', 'SamsungSharpSans-Bold', 700, normal);
// @include fontFace('iCiel Samsung Sharp Sans', '../../fonts/', 'iCielSamsungSharpSans-Bold', 700, normal);

@font-face {
  font-family: "SamsungOne";
  src: url("../../fonts/SamsungOne-400.svg#SamsungOne-400") format("svg"), url("../../fonts/SamsungOne-400.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SamsungOne";
  src: url("../../fonts/SamsungOne-400.svg#SamsungOne-400") format("svg"), url("../../fonts/SamsungOne-400.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "iCiel Samsung Sharp Sans";
  src: url("../../fonts/SamsungSharpSansBd.eot");
  src: url("../../fonts/SamsungSharpSansBd.eot?#iefix") format("embedded-opentype"), url("../../fonts/SamsungSharpSansBd.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@include fontFace('icomoon', '../../fonts/', 'icomoon', 400, normal);