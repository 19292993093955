$primary-font: 'SamsungOne',
sans-serif !default;

$secondary-font: 'iCiel Samsung Sharp Sans',
sans-serif !default;

$icon-font: 'icomoon',
sans-serif !default;

$white-color: #fff !default;
$black-color: #000 !default;
$yellow-color: #FFD12D !default;
$blue-color: #3AA1FF !default;
$red-color: #C90D0C !default;
$orange-color: #e35b07 !default;