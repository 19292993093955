.loading {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba($black-color, 0.75);
	z-index: 999;

	&-icon {
		width: 48px;
		height: 48px;
		border-radius: 50%;
		display: inline-block;
		position: relative;
		border: 3px solid;
		border-color: #fff #fff transparent transparent;
		box-sizing: border-box;
		animation: rotation 1s linear infinite;

		&::after,
		&::before {
			content: '';
			box-sizing: border-box;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			border: 3px solid;
			border-color: transparent transparent #8e826f #8e826f;
			width: 40px;
			height: 40px;
			border-radius: 50%;
			box-sizing: border-box;
			animation: rotationBack 0.5s linear infinite;
			transform-origin: center center;
		}

		&::before {
			width: 32px;
			height: 32px;
			border-color: #fff #fff transparent transparent;
			animation: rotation 1.5s linear infinite;
		}
	}
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes rotationBack {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(-360deg);
	}
}