.store-list {
	position: relative;
	padding: 30px 0;

	@include minW(992) {
		padding: 60px 0;
	}

	&__body {
		padding: 0 100px;
	}

	.box-light {
		&__body {
			padding-bottom: 40px;
		}
	}

	// .table {
	// 	thead {
	// 		th {
	// 			&:first-child {
	// 				width: 14%;

	// 				@include minW(640) {
	// 					width: 12%;
	// 				}
	// 			}

	// 			&:nth-child(2) {
	// 				width: 22%;
	// 				@include minW(640) {
	// 					width: 20%;
	// 				}
	// 			}
	// 		}
	// 	}
	// }
}