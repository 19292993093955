.join {
	position: relative;
	text-align: center;
	padding: 30px 0;

	@include minW(992) {
		padding: 60px 0;
	}

	&__head {
		width: 100%;
		max-width: 480px;
		margin: 0 auto;
	}

	&__body {

		@include minW(992) {
			display: flex;
			margin: 0 -32px;
		}
	}

	&__foot {
		margin-top: 32px;
	}
}

.card-join {
	position: relative;
	font-size: vwm(22px);

	@include minW(640) {
		font-size: vwt(17px);
	}

	@include minW(992) {
		padding: 0 32px;
		width: 33.33333%;
		flex-basis: 33.33333%;
		font-size: vwd(17px);
	}

	@include minW(1440) {
		font-size: 17px;
	}

	&__title {
		font-family: $secondary-font;
		color: #c1b396;
		font-size: 18px;
		margin-bottom: 4px;
	}

	&__note {
		font-size: vwm(18px);

		@include minW(640) {
			font-size: vwt(14px);
		}

		@include minW(992) {
			font-size: vwt(14px);
		}

		@include minW(1440) {
			font-size: 14px;
		}
	}

	&__desc {
		margin-bottom: 0;
	}
}

.try-now {

	@include minW(640) {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.title {
		font-size: 20px;

		@include minW(640) {
			text-align: right;
			font-size: 24px;
			margin-bottom: 0;
		}

		@include minW(992) {
			font-size: 30px;
		}
	}

	h3 {
		font-size: 24px;
		margin-bottom: 0;

		@include minW(640) {
			font-size: 30px;
		}

		@include minW(992) {
			font-size: 34px;
		}
	}

	img {
		max-width: 117px;
		margin-bottom: 10px;

		@include minW(640) {
			margin: 0 16px;
		}
	}
}