.table-responsive {
	display: block;
	width: 100%;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;
}

.table {
	width: 100%;
	max-width: 100%;
	min-width: 500px;
	margin-bottom: 16px;
	background-color: transparent;
	font-size: vwm(17px);

	@include minW(640) {
		font-size: vwt(17px);
	}

	@include minW(992) {
		font-size: vwd(17px);
	}

	@include minW(1440) {
		font-size: 17px;
	}

	th,
	td {
		padding: 8px;
    vertical-align: center;
	}

	thead {
		th {
			vertical-align: bottom;
    	border-bottom: 1px solid #7e7d7b;
		}
	}

	tbody {
		tr {
			&:nth-child(2n) {
				background-color: #221f19;

				td {
					&:first-child {
						border-radius: 30px 0 0 30px;
					}

					&:last-child {
						border-radius: 0 30px 30px 0;
					}
				}
			}
		}
	}
}