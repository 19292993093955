.luckydraw {
	position: relative;
	padding: 30px 0;
	width: 100%;

	@include minW(992) {
		padding: 60px 0;
	}
}

.wheel {
	position: relative;
	text-align: center;

	@include minW(992) {
		display: flex;
		align-items: center;
		text-align: center;
	}

	&__title {
		font-size: vwm(25px);
		margin-bottom: 8px;

		@include minW(640) {
			font-size: vwt(25px);
		}

		@include minW(992) {
			font-size: vwd(25px);
			margin-bottom: 0;
		}

		@include minW(1440) {
			font-size: 25px;
		}
	}

	&__head {

		@include minW(992) {
			width: 50%;
			flex-basis: 50%;
			padding: 0 16px;
			order: 1;
		}
	}

	&__body {
		@include minW(992) {
			width: 50%;
			flex-basis: 50%;
			order: 0;
		}
	}

	&__desc {
		font-size: vwm(24px);

		@include minW(640) {
			font-size: vwt(18px);
		}

		@include minW(992) {
			font-size: vwd(18px);
		}

		@include minW(1440) {
			font-size: 18px;
		}

		span {
			display: block;
		}
	}
}

.winwheel {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	&::before {
		content: '';
		display: block;
		background: url('../../images/bg-wheel.png') no-repeat center;
		background-size: cover;
		width: vwm(900px);
		height: vwm(900px);
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);

		@include minW(640) {
			width: vwt(805px);
			height: vwt(805px);
		}

		@include minW(992) {
			width: vwd(805px);
			height: vwd(805px);
			top: 50%;
		}

		@include minW(1440) {
			width: 805px;
			height: 805px;
		}
	}

	&__body {
		position: relative;
		width: vwm(450px);
		height: vwm(450px);

		@include minW(640) {
			width: vwt(500px);
			height: vwt(500px);
		}

		@include minW(992) {
			width: vwd(440px);
			height: vwd(440px);
		}

		@include minW(1440) {
			width: 440px;
			height: 440px;
		}

		&::after {
			content: '';
			display: block;
			background-image: url('../../images/arrow-spin.png');
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			position: absolute;
			width: 30px;
			height: 26px;
			bottom: -5px;
			left: 50%;
			transform: translateX(-50%);
			z-index: 3;

			@include minW(640) {
				width: 39px;
				height: 34px;
			}
		}

		canvas {
			position: relative;
			z-index: 2;
			width: 100% !important;
			height: 100% !important;

			&.animate-wheel {
				animation: clockwise 50s linear infinite;
			}
		}
	}

	&__spin {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;
		font-size: vwm(16px);
		text-transform: uppercase;
		width: vwm(85px);
		margin: 0;
		padding: 0;
		font-family: $secondary-font;
		color: #b2aca0;
		cursor: pointer;
		background-color: transparent;
		border: 0;
		text-align: center;

		@include minW(640) {
			font-size: vwt(16px);
			width: vwt(60px);
		}

		@include minW(992) {
			font-size: vwd(16px);
			width: vwd(60px);
		}

		@include minW(1440) {
			font-size: 16px;
			width: 60px;
		}

		&[disabled] {
			pointer-events: none;
			cursor: default;
		}
	}

	&__action {
		margin-top: 30px;
		font-size: vwm(22px);

		@include minW(640) {
			font-size: vwt(20px);
		}

		@include minW(992) {
			font-size: vwd(20px);
			display: flex;
			align-items: center;
		}

		@include minW(1440) {
			font-size: 20px;
		}

		.number {
			color: #c1b396;
		}
	}
}

@keyframes clockwise {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.08, 1.08, 1.08);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.pulse {
	animation-duration: 1.5s;
  animation-name: pulse;
  animation-timing-function: ease-in-out;
	animation-fill-mode: both;
	animation-iteration-count: infinite;
	display: block;
}

.number-wheel {
	text-align: right;
	position: relative;
	// padding: 12px;

	// @include minW(992) {
	// 	padding: 30px;
	// }

	// &::after {
	// 	content: '';
	// 	display: none;
	// 	width: 1px;
	// 	height: 55px;
	// 	background-color: #ccc;
	// 	position: absolute;
	// 	right: 15px;
	// 	top: 50%;
	// 	transform: translateY(-50%);

	// 	@include minW(992) {
	// 		display: block;
	// 	}
	// }

	// &__break {
	// 	@include minW(992) {
	// 		display: block;
	// 	}
	// }
}

.prize-total {
	font-size: vwm(18px);

	@include minW(640) {
		font-size: vwt(18px);
	}

	@include minW(992) {
		text-align: left;
		font-size: vwd(16px);
	}

	@include minW(1440) {
		font-size: 16px;
	}

	span {
		display: block;
	}

	strong {
		color: #c1b396;
	}
}